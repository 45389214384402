/* eslint-disable react-hooks/rules-of-hooks */
import { ArrowLeftOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';
import Pagination from '@material-ui/lab/Pagination';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { Buffer } from 'buffer';
import { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ImgProgramacao from '../../assets/img/box-curso/programacao.svg';
import { Avatar } from '../../components/Avatar';
import DivStatusCobranca from '../../components/DivStatusCobranca';
import { ModalEstorno } from '../../components/ModalEstorno';
import { ModalFinanceiro } from '../../components/ModalFinanceiro';
import { ModalFinanceiroCancelamentoStep2 } from '../../components/ModalFinanceiro/cancelamento-2';
import { ModalBaixaNew } from '../../components/ModalFinanceiro/modal-baixa-new';
import NewMenu from '../../components/NewMenu';
import { Tags } from '../../components/Tags/index';
import Title from '../../components/Title/title.component';
import ModalEditarVencimento from '../../components/modal-editar-vencimento';
import { useAlunoContext } from '../../context/AlunoContext';
import { useGlobalContext } from '../../context/GlobalContext';
import { ContatoEnum } from '../../enum/contato.enum';
import { FuncaoEnum } from '../../enum/funcao.enum';
import { Operators } from '../../enum/operators.enum';
import { PerfilEnum } from '../../enum/perfil.enum';
import { TipoDocumentoEnum } from '../../enum/tipoDocumento.enum';
import { ICobrancaCustomDTO, IMatriculaCustomDTO, IPessoaResponsavelCustomDTO } from '../../models/happy-code-api.model';
import { Predicate } from '../../models/predicate.model';
import { IDataNotas } from '../../models/relatorio.model';
import { PageableResponse } from '../../models/response.model';
import { useAlunoService } from '../../services/aluno.service';
import { useCobrancaService } from '../../services/cobranca.service';
import { useEnotasService } from '../../services/enotas.service';
import { useMatriculaService } from '../../services/matricula.service';
import { useNotificationService } from '../../services/notification.service';
import { formatMoney, formattedDate, isAfterDate, isBeforeDate } from '../../util/format';
import useCalculateDays from './hooks/use-calculate-days';
import usePermissionBaixaHook from './hooks/use-permission-baixa-hook';
import ModalEditarPagamento from './pages/detalhe-aluno/components/modal-change-payment/modal-change-payment-component';
import StudentCourses from './pages/detalhe-aluno/components/student-courses/student-courses.component';
import './style.css';

export const AlunosDetalhe: React.FunctionComponent = () => {
	const matriculaService = useMatriculaService();
	const alunoService = useAlunoService();
	const cobrancaService = useCobrancaService();
	const enotasService = useEnotasService();
	const { setIsGlobalLoading } = useGlobalContext();
	const { aluno, setRevisaoDetalhe, setRevisao, setAluno, setMatricula, removeMatricula, matricula, matriculas, setMatriculas } = useAlunoContext();
	const { usuario } = useGlobalContext();
	const notification = useNotificationService();
	const history = useHistory();
	const [open, setOpen] = useState({});
	const [primeiro, setPrimeiro] = useState(true);
	const [cobrancas, setCobrancas] = useState<PageableResponse<ICobrancaCustomDTO>>();
	const [cursosAndamento, setCursosAndamento] = useState<number>(0);
	const [predicate] = useState<Predicate>(new Predicate());
	const [isOpen, setIsOpen] = useState(false);
	const [matriculasEmAndamento, setMatriculasEmAndamento] = useState<IMatriculaCustomDTO[]>([]);
	const [cobrancaSelected, setCobrancaSelected] = useState<ICobrancaCustomDTO>();
	const [mostrarModalCancelamento, setMostrarModalCancelamento] = useState(false);
	const [mostrarModalEditarVencimento, setMostrarModalEditarVencimento] = useState(false);
	const [mostrarModalEstorno, setMostrarModalEstorno] = useState(false);
	const [openRematricula, setOpenRematricula] = useState<boolean>(false);
	const [mostrarModalEditarPagamento, setOpenMostrarModalEditarPagamento] = useState<boolean>(false);
	const [mostrarModalBaixaManual, setMostrarModalBaixaManual] = useState<boolean>(false);
	const { idAluno } = useParams<any>();
	const { calculateDaysBetweenNoWeekend } = useCalculateDays();
	const { canBaixaManual, handleDownloadBaixa } = usePermissionBaixaHook();

	const hasPermissionToChangePaymentType = () => {
		if (usuario.perfil === PerfilEnum.SEDE) return true;
		if (usuario.funcao === FuncaoEnum.TECNOLOGIA_CODIGO || usuario.funcao === FuncaoEnum.ADMINISTRADOR_CODIGO) return true;
		return false;
	};

	const handleMostrarMais = (index: number) => {
		setOpen((prevState) => ({ ...prevState, [index]: !prevState[index] }));
	};

	const isDocumentoEnviado = (tipoDocumentoEnum: TipoDocumentoEnum) => {
		return aluno.pessoa.documentos.find((documento) => documento?.tipoDocumentoId === tipoDocumentoEnum);
	};

	const descricaoProduto = (cobranca: ICobrancaCustomDTO): string => {
		let descricaoNegocios = cobranca?.financeiro?.matriculas 
			.map((m) => (m?.turma?.curriculo ? m?.turma?.curriculo?.descricaoCurriculo : m?.turma?.curso?.descricao))

		const financeiroOrigem = cobranca?.financeiro?.financeiroOrigem;
		
		if(financeiroOrigem){ 
			const contratoAnteriorUpgrade = matriculas.find((m) => m?.financeiro?.id == financeiroOrigem)
			const descricaoContratoAnterior = contratoAnteriorUpgrade.financeiro?.matriculas
				.map((m) => (m?.turma?.curriculo ? m?.turma?.curriculo?.descricaoCurriculo : m?.turma?.curso?.descricao))

			descricaoNegocios = [...descricaoNegocios, ...descricaoContratoAnterior];
		};

		return descricaoNegocios.join(', ');
	};

	const fetchCobrancasComFinanceiro = async () => {
		predicate.sort = 'asc';
		predicate.addOption('idAluno', aluno.id);

		if (location.search == '?emaberto') {
			predicate.addMultiOption('statusCobrancaId', 1, Operators.OR);
			predicate.addMultiOption('statusCobrancaId', 2, Operators.OR);
			predicate.addMultiOption('statusCobrancaId', 7, Operators.OR);
		}
		predicate.addSort('financeiro.id');
		return cobrancaService.findCustomComFinanceiro(predicate).then(({ data }) => {
			setCobrancas(data);
		});
	};

	const onPageChange = (event: ChangeEvent<unknown>, page: number) => {
		event.preventDefault();
		predicate.setPage(page);
		setIsGlobalLoading(true);
		fetchCobrancasComFinanceiro().finally(() => setIsGlobalLoading(false));
	};

	const checkAndamento = (dataInicial: Date, dataFinal: Date) => {
		return isAfterDate(dataInicial) && isBeforeDate(dataFinal);
	};

	let celular;
	
	const handleNovoCursoClick = (id: number[]) => {
		aluno.pessoa.pessoaResponsaveis.forEach((pessoaResp: IPessoaResponsavelCustomDTO) => {
			if (!pessoaResp.responsavel.nome) {
				notification({
					description: 'Preencha o nome do responsável',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			if (pessoaResp?.responsavel?.cpf == '           ' || pessoaResp?.responsavel?.cpf.length < 11) {
				notification({
					description: 'Preencha o CPF do responsável',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			if (!pessoaResp.responsavel.dataNascimento) {
				notification({
					description: 'Preencha a data de nascimento do responsável',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			if (!pessoaResp.responsavel.endereco.cep) {
				notification({
					description: 'Preencha o cep do responsável',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			if (!pessoaResp.responsavel.endereco.logradouro) {
				notification({
					description: 'Preencha o endereço do responsável',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			if (!pessoaResp.responsavel.endereco.numero) {
				notification({
					description: 'Preencha o número do endereço do responsável',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			if (!pessoaResp.grauParentesco) {
				notification({
					description: 'Preencha o grau de parentesco do responsável',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			}
			celular = pessoaResp.responsavel.contatos.find((c) => c.tipoContatoId === ContatoEnum.CEL) ? true : false;
			if (!celular) {
				notification({
					description: 'Preencha o telefone do responsável',
					type: 'warning',
					message: 'Aviso!',
				});
				return;
			} else {
				matricula.rematricula = false;

				setMatricula({ ...matricula, aluno });

					history.push('/matriculas/create/curso/', { curriculoSelecionado: id});
				}
			}
		);
	};

	const isResponsavelOK = () => {
		let problema = '';

		aluno.pessoa.pessoaResponsaveis.forEach((pessoaResp: IPessoaResponsavelCustomDTO) => {
			if (!pessoaResp.responsavel.nome) {
				problema = 'Preencha o nome do responsável';
				return;
			}
			if (pessoaResp?.responsavel?.cpf.trim().length < 11) {
				problema = 'Preencha o CPF do responsável';
				return;
			}
			if (!pessoaResp.responsavel.dataNascimento) {
				problema = 'Preencha a data de nascimento do responsável';
				return;
			}
			if (!pessoaResp.responsavel.endereco.cep) {
				problema = 'Preencha o cep do responsável';
				return;
			}
			if (!pessoaResp.responsavel.endereco.logradouro) {
				problema = 'Preencha o endereço do responsável';
				return;
			}
			if (!pessoaResp.responsavel.endereco.numero) {
				problema = 'Preencha o número do endereço do responsável';
				return;
			}
			if (!pessoaResp.grauParentesco) {
				problema = 'Preencha o grau de parentesco do responsável';
				return;
			}
			celular = !!pessoaResp.responsavel.contatos.find((c) => c.tipoContatoId === ContatoEnum.CEL);
			if (!celular) {
				problema = 'Preencha o telefone do responsável';
				return;
			}
		});

		if (problema) {
			notification({
				description: problema,
				type: 'warning',
				message: 'Aviso!',
			});

			return false;
		} else return true;
	};

	const handleSubmitMatriculaAtiva = (matriculaSelecionada: IMatriculaCustomDTO) => {
		setOpenRematricula(false);
		if (isResponsavelOK()) {
			matricula.rematricula = true;
			matricula.financeiroRematricula = matriculaSelecionada.financeiro;
			setMatricula({ ...matricula, aluno });
			history.push(`/matriculas/create/curso/`, { curriculoSelecionado: null });
		}
	};

	const findMatriculasEmAndamento = (id: number) => {
		if (isResponsavelOK()) {
			matriculaService.findMatriculasEmAndamento(id)
				.then(({ data }) => {
					if (data.length > 0) {
						setMatriculasEmAndamento(data);
						//if (data.length == 1) handleSubmitMatriculaAtiva(data[0]);
					}
				})
		}
	};

	const fetchMatricula = async () => {
		if (!aluno?.id) return;
		const predicateMatricula = new Predicate();
		predicateMatricula.addOption('aluno.id', aluno.id);
		predicateMatricula.size = 50;
		return matriculaService.findCustom(predicateMatricula).then((matriculas) => {
			setMatriculas(matriculas.data);
		});
	};

	const getCelularOuTelefone = () => {
		return (
			aluno.pessoa.contatos?.find((c) => c.tipoContatoId === ContatoEnum.CEL)?.descricao ||
			aluno.pessoa.contatos?.find((c) => c.tipoContatoId === ContatoEnum.TEL)?.descricao ||
			'N/A'
		);
	};

	const handleEditarClick = (step: string) => {
		const revisaoDetalhe = {
			ativo: true,
			alunoId: aluno.id,
		};
		setRevisao(false);
		setRevisaoDetalhe(revisaoDetalhe);
		history.push(`/alunos/create/${step}`);
	};

	const getEnderecoResponsavel = () => {
		return (aluno.pessoa?.pessoaResponsaveis.length > 0 && aluno.pessoa?.pessoaResponsaveis[0].responsavel?.endereco?.logradouro) || 'N/A';
	};

	const reiniciarCobranca = (cobrancaId: number) => {
		setIsGlobalLoading(true);
		cobrancaService
			.reiniciarCobranca(cobrancaId)
			.then(() => {
				notification({
					description: `A cobrança foi atualizada com sucesso!!`,
					type: 'success',
					message: 'Atualizado!',
				});
				fetchCobrancasComFinanceiro();
			})
			.catch(() =>
				notification({
					description: `Houve uma falha ao atualizar!`,
					type: 'error',
					message: 'Falha ao atualizar!',
				})
			)
			.finally(() => setIsGlobalLoading(false));
	};

	const cancelarPedido = (order: string) => {
		setIsGlobalLoading(true);
		cobrancaService
			.cancelarOrder(order)
			.then(() =>
				notification({
					description: 'O pedido foi cancelado!',
					type: 'success',
					message: 'Aviso!',
				})
			)
			.catch(() =>
				notification({
					description: 'Houve uma falha ao cancelar o pedido!',
					type: 'warning',
					message: 'Aviso!',
				})
			)
			.finally(() => setIsGlobalLoading(false));
	};

	const baixarNota = (notaFiscal: IDataNotas) => {
		if (notaFiscal?.id == null) {
			Modal.error({
				title: 'Não foi possível baixar a nota fiscal',
				content: `${notaFiscal[0].codigo + ' - ' + notaFiscal[0].mensagem}`,
			});
		} else {
			if (notaFiscal.status === 'Autorizada') {
				if (notaFiscal.tipo === 'NF-e') {
					window.open(notaFiscal?.linkDanfe);
				} else {
					window.open(notaFiscal?.linkDownloadPDF);
				}
			} else {
				Modal.error({
					title: 'Não foi possível baixar a nota fiscal',
					content: `${notaFiscal?.status + ' - ' + notaFiscal?.motivoStatus}`,
				});
			}
		}
	};

	const buscarNotaServico = (idCobranca) => {
		setIsGlobalLoading(true);
		enotasService
			.buscarNotaServico(idCobranca)
			.then((response) => {
				baixarNota(response.data);
			})
			.catch(() => {
				notification({
					description: 'A nota não foi gerada',
					type: 'warning',
					message: 'Aviso!',
				});
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const buscarNotaProduto = (idCobranca) => {
		setIsGlobalLoading(true);
		enotasService
			.buscarNotaProduto(idCobranca)
			.then((response) => {
				baixarNota(response.data);
			})
			.catch(() => {
				notification({
					description: 'A nota não foi gerada',
					type: 'warning',
					message: 'Aviso!',
				});
			})
			.finally(() => setIsGlobalLoading(false));
	};

	const turmaEmAndamento = () => {
		//Verifica turma/cursos em andamento
		const andamentoAux = matriculas.length > 0 && matriculas.filter((e) => checkAndamento(e.turma?.dataInicio, e.turma?.dataFim)).length;
		setCursosAndamento(andamentoAux);
	};

	// Declaração de Modais
	const finalizaNegociacao = () => {
		setIsOpen(false);
		fetchCobrancasComFinanceiro();
	};

	const abrirNegociacao = (cobranca) => {
		setCobrancaSelected(cobranca);
		setIsOpen(true);
	};

	const estornar = (cobranca) => {
		setCobrancaSelected(cobranca);
		setMostrarModalEstorno(true);
	};

	const abrirCancelamento = (cobranca) => {
		setCobrancaSelected(cobranca);
		setMostrarModalCancelamento(true);
	};

	const editarVencimento = (cobranca) => {
		setCobrancaSelected(cobranca);
		setMostrarModalEditarVencimento(true);
	};

	const abrirAlterarPagamento = (cobranca) => {
		setCobrancaSelected(cobranca);
		setOpenMostrarModalEditarPagamento(true);
	};

	const abrirBaixaManual = (cobranca) => {
		setCobrancaSelected(cobranca);
		setMostrarModalBaixaManual(true);
	};

	const finalizaCancelamento = () => {
		setMostrarModalCancelamento(false);
		fetchCobrancasComFinanceiro();
	};

	const closeModalEstorno = () => {
		setMostrarModalEstorno(false);
		fetchCobrancasComFinanceiro();
	};

	const closeModal = () => {
		setMostrarModalEditarVencimento(false);
		fetchCobrancasComFinanceiro();
	};
	const closeModalAlterarPagamento = () => {
		setOpenMostrarModalEditarPagamento(false);
		fetchCobrancasComFinanceiro();
	};
	const closeBaixaManual = () => {
		setMostrarModalBaixaManual(false);
		fetchCobrancasComFinanceiro();
	};

	const fetchAlunoRematricula = async (id) => {
		try {
			const { data } = await alunoService.findCustomById(id);
			setAluno(data);

			if (data) {
				setOpenRematricula(true);
				findMatriculasEmAndamento(data?.id);
			} else {
				notification({
					description: 'Aluno não encontrado!',
					type: 'error',
					message: 'Erro!',
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (idAluno) {
			fetchAlunoRematricula(idAluno);
		}

		removeMatricula();
		if (!aluno?.id) return;
		setIsGlobalLoading(true);
		Promise.all([findMatriculasEmAndamento(aluno?.id), fetchMatricula(), fetchCobrancasComFinanceiro(), turmaEmAndamento()]).finally(() => {
			setIsGlobalLoading(false);
			setPrimeiro(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [aluno?.id]);

	const itensDropdown = (cobranca: ICobrancaCustomDTO) => {
		const menuItem2aVia = (semMulta: boolean) => {
			let parametro = '';
			let descricao = '';

			if (semMulta) {
				parametro = cobranca.id.toString().padStart(9, '0') + new Date().toISOString().substring(0, 10);

				parametro = '?' + Buffer.from(parametro).toString('base64');
				descricao = '- sem multa de atraso';
			}

			return (
				cobranca?.statusCobranca?.codigo !== 'PGO' &&
				cobranca?.statusCobranca?.codigo !== 'IST' &&
				cobranca?.statusCobranca?.codigo !== 'CAN' && (
					<Menu.Item>
						<li>
							<a
								target="blank"
								href={`/checkout/${cobranca.codigoPedido}${parametro}`}
								onClick={() =>
									descricao === '- sem multa de atraso'
										? notification({
												description: 'Este checkout terá duração até a meia noite de hoje!',
												type: 'warning',
												message: 'Aviso!',
												duration: 0,
										  })
										: null
								}
								rel="noopener noreferrer"
							>
								Emitir 2ª. via {descricao}
							</a>
						</li>
					</Menu.Item>
				)
			);
		};

		return (
			<Menu>
				{menuItem2aVia(false)}
				{isAfterDate(cobranca.dataVencimento) && menuItem2aVia(true)}

				{['GRD', 'FLH', 'AGR'].includes(cobranca?.statusCobranca?.codigo) && hasPermissionToChangePaymentType() && (
					<Menu.Item>
						<li onClick={() => abrirAlterarPagamento(cobranca)}>
							<a>Alterar Forma de Pagamento</a>
						</li>
					</Menu.Item>
				)}
				{['AGR', 'GRD', 'CAN', 'FLH'].includes(cobranca?.statusCobranca?.codigo) && canBaixaManual && (
					<Menu.Item>
						<li onClick={() => abrirBaixaManual(cobranca)}>
							<a>Baixa Manual de Pagamento</a>
						</li>
					</Menu.Item>
				)}
				{cobranca?.codigoArquivoBaixa && canBaixaManual && (
					<Menu.Item>
            <li onClick={() => handleDownloadBaixa(cobranca.codigoArquivoBaixa)}>
							<a>Baixar comprovante de pagamento</a>
						</li>
					</Menu.Item>
				)}
				{cobranca.statusCobranca?.codigo === 'GRD' && cobranca?.tipoPagamento?.codigo === 'PMF' && cobranca.order != null && (
					<Menu.Item>
						<a onClick={() => cancelarPedido(cobranca.order)}>Cancelar pedido Máquina Física</a>
					</Menu.Item>
				)}

				{usuario.perfil === 'SEDE' ? (
					<>
						{cobranca?.statusCobranca?.codigo === 'FLH' ||
						cobranca?.statusCobranca?.codigo === 'AGR' ||
						(cobranca?.statusCobranca?.codigo === 'GRD' && cobranca?.tipoPagamento?.codigo !== 'CRR') ? (
							<Menu.Item>
								<li>
									<a onClick={() => editarVencimento(cobranca)}>Editar vencimento</a>
								</li>
							</Menu.Item>
						) : null}

						{cobranca?.statusCobranca?.codigo === 'FLH' || (cobranca?.statusCobranca?.codigo === 'GRD' && cobranca?.tipoPagamento?.codigo !== 'CRR') ? (
							<Menu.Item>
								<li>
									<a onClick={() => reiniciarCobranca(cobranca?.id)}>Reiniciar cobrança</a>
								</li>
							</Menu.Item>
						) : null}

						{usuario.funcao === FuncaoEnum.GESTAO_FINANCEIRO_CODIGO ||
						usuario.funcao === FuncaoEnum.ADMINISTRADOR_CODIGO ||
						usuario.funcao === FuncaoEnum.TECNOLOGIA_CODIGO ? (
							<>
								{cobranca?.statusCobranca?.codigo === 'PGO' && cobranca?.tipoPagamento?.codigo !== 'BOL' ? (
									<Menu.Item>
										<li>
											<a onClick={() => estornar(cobranca)}>Estornar cobrança</a>
										</li>
									</Menu.Item>
								) : null}
							</>
						) : null}

						{cobranca?.statusCobranca?.codigo !== 'PGO' && cobranca.statusCobranca?.codigo !== 'IST' ? (
							<Menu.Item>
								<a onClick={() => abrirNegociacao(cobranca)} rel="noopener noreferrer">
									Renegociação
								</a>
							</Menu.Item>
						) : null}
					</>
				) : null}

				{cobranca?.identificadorEnotas !== undefined ? (
					<Menu.Item>
						<li>
							<a onClick={() => buscarNotaServico(cobranca?.id)}>Nota de serviço</a>
						</li>
					</Menu.Item>
				) : null}

				{cobranca?.identificadorEnotasProduto !== undefined ? (
					<Menu.Item>
						<li>
							<a onClick={() => buscarNotaProduto(cobranca?.id)}>Nota de produto</a>
						</li>
					</Menu.Item>
				) : null}
				{(cobranca?.statusCobranca?.codigo !== 'PGO' && cobranca.statusCobranca?.codigo !== 'CAN') || cobranca?.financeiro.cobrancaUnica == true ? (
					<Menu.Item>
						<li>
							<a onClick={() => abrirCancelamento(cobranca)} rel="noopener noreferrer">
								Cancelar Matricula
							</a>
						</li>
					</Menu.Item>
				) : null}
			</Menu>
		);
	};

	const dropDownForBaixa = (cobranca: ICobrancaCustomDTO) => {
		return (
			<Menu>
					<Menu.Item>
						<li onClick={() => handleDownloadBaixa(cobranca.codigoArquivoBaixa)}>
							<a>Baixar comprovante de pagamento</a>
						</li>
					</Menu.Item>
			</Menu>
		)
	}
	const MatriculasEmAndamento = (): React.ReactElement => {
		return (
			<div className="modalContainer">
				<div className="modal-user-franquia" style={{ width: '900px' }}>
					<div className="space1"></div>
					<div className="franquia-row" id="row-aluno-box" style={{ margin: 0, alignSelf: 'center' }}>
						<h3>Selecione o currículo </h3>
						<div className="line" />
						<div className="form-modal-oferta">
							<div className="box-cursos" style={{ padding: 0, height: 'auto', maxHeight: 600 }}>
								<div className="boxes-cursos" style={{ overflowY: 'scroll', marginBottom: 0 }}>
									{matriculasEmAndamento?.length > 0 &&
										matriculasEmAndamento?.map((matriculaAtiva) => (
											<div
												className="curso"
												key={matriculaAtiva.id}
												style={{
													cursor: 'pointer',
													maxWidth: '50%',
													width: '50%',
												}}
												onClick={() => handleSubmitMatriculaAtiva(matriculaAtiva)}
											>
												<img src={ImgProgramacao} alt="" />
												<div className="data" style={{ textAlign: 'left' }}>
													<h5>#{matriculaAtiva.id}</h5>
													<h5>{matriculaAtiva?.turmaCurso?.descricao || matriculaAtiva?.turmaCurriculoNegocioDescricao}</h5>
													<h4 style={{ font: '-webkit-control' }}>Saldo em aberto: {formatMoney(matriculaAtiva.financeiro.saldoAPagar)}</h4>
													<h4 style={{ font: '-webkit-control' }}>Parcela: {formatMoney(matriculaAtiva.financeiro.valorMensalidadeComDesconto)}</h4>
												</div>
											</div>
										))}
								</div>
								<Button onClick={() => setOpenRematricula(false)} className="button-second" style={{ alignSelf: 'center', marginTop: 20 }}>
									Fechar
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="container-fondo">
			<NewMenu selecionado={10} />
			<div className="franquia" id="aluno-detalhe-row" style={{ backgroundColor: '#F5F5F9' }}>
				<div className="franquia-row" id="row-aluno-box">
					<div className="head-botoes">
						<Button onClick={() => history.goBack()} className="button-second" icon={<ArrowLeftOutlined rev={undefined} />}>
							Voltar
						</Button>
						<Title title={'Detalhes do aluno'} />
					</div>
					<div className="box-data-aluno">
						<div className="data-aluno">
							<Avatar size={115} arquivo={aluno?.pessoa?.foto} />
							<div className="data">
								<div className="head">
									<h3>{aluno.pessoa.nome}</h3>
									{usuario?.funcao === 'TEC' &&(
										<h5>{aluno?.unidade.descricao}</h5>
									)}
									<h5>{cursosAndamento} cursos em andamento</h5>
								</div>
								<div className="footer">
									<h5>
										<Unicons.UilLocationPoint size="12" />
										{getEnderecoResponsavel()}
									</h5>
									<div className="email">
										<p>
											<Unicons.UilPhone size="12" /> {getCelularOuTelefone()}
										</p>
										<p>
											<Unicons.UilEnvelopeAlt size="12" />
											{aluno.pessoa.contatos?.find((c) => c.tipoContatoId === ContatoEnum.EPS)?.descricao || 'N/A'}
										</p>
									</div>
								</div>
							</div>
							<div className="box-dot">
								<div className={aluno.ativo ? 'dot' : 'dot-vermelho'}></div>
								<p className={aluno.ativo ? 'ativo' : 'inativo'}>{aluno.ativo ? 'ATIVO' : 'INATIVO'}</p>
							</div>
						</div>
					</div>
					<StudentCourses
						fetchAlunoRematricula={() => {
							findMatriculasEmAndamento(aluno?.id);
							setOpenRematricula(true);
						}}
						handleNovoCursoClick={(id) => handleNovoCursoClick(id)}
						rematriculas={matriculasEmAndamento}
					/>
					<div className="row-revisao">
						<div className="box-revisao">
							<div className="dados">
								<div className={'title-and-edit-button'}>
									<h4>Dados Básicos</h4>

									<Button onClick={() => handleEditarClick('step-1')} className="button-line">
										Editar
									</Button>
								</div>
								<div className="box-aluno">
									<Avatar size={150} arquivo={aluno?.pessoa?.foto} />
									<div className="dados-colaborador">
										<div className="nome">
											<h5>Nome Completo</h5>
											<span style={{ fontSize: 18 }}>{aluno.pessoa.nome}</span>
										</div>
										<div className="dados-franquia">
											<div className="cpf">
												<h6>CPF</h6>
												<span> {aluno.pessoa.cpf}</span>
											</div>
											<div className="cpf">
												<h6>Data de Nascimento</h6>
												<span>{formattedDate(aluno.pessoa.dataNascimento)}</span>
											</div>
										</div>
										<div className="dados-franquia">
											<div className="cpf">
												<h6>Sexo</h6>
												<span>{aluno.pessoa.genero?.descricao || 'N/A'}</span>
											</div>
											<div className="cpf">
												<h6>Status</h6>
												<span>{aluno.ativo ? 'ATIVO' : 'INATIVO'}</span>
											</div>
										</div>
										<div className="dados-franquia">
											<div className="cpf" style={{ marginRight: 45 }}>
												<h6>Observações</h6>
												<span>{aluno.observacao || 'N/A'}</span>
											</div>
										</div>
										{usuario?.funcao === 'TEC' && (
											<div className="dados-franquia">
												<div className="teste" style={{ marginRight: 45 }}>
													<h6>Aluno teste</h6>
													<span>{aluno.pessoa.pessoaTeste ? 'Sim' : 'Não'}</span>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="line" style={{ marginBottom: 20 }}></div>
							<div className="dados">
								<div className={'title-and-edit-button'}>
									<h4>Responsáveis</h4>
									<Button className="button-line" onClick={() => handleEditarClick('step-3')}>
										Editar
									</Button>
								</div>

								{aluno &&
									aluno?.pessoa?.pessoaResponsaveis
										.filter((responsavel) => responsavel?.ativo)
										.map((pessoaResponsavel, index) => (
											<div className="resposavel-revisao" key={index}>
												<h4>Responsável {index + 1}</h4>
												<div className="nome">
													<h5>Nome Completo</h5>
													<span style={{ fontSize: 18 }}>{pessoaResponsavel.responsavel.nome}</span>
												</div>
												<div className="dados-franquia">
													<div className="cpf">
														<h6>CPF</h6>
														<span>{pessoaResponsavel.responsavel.cpf}</span>
													</div>
													<div className="cpf">
														<h6>Data de Nascimento</h6>
														<span>{formattedDate(pessoaResponsavel?.responsavel?.dataNascimento)}</span>
													</div>
												</div>
												<div className="dados-franquia">
													<div className="cpf">
														<h6>Grau Parentesco</h6>
														<span>{pessoaResponsavel.grauParentesco}</span>
													</div>
												</div>
												{usuario?.funcao === 'TEC' && (
													<div className="dados-franquia">
														<div className="teste" style={{ marginRight: 45 }}>
															<h6>Responsavel teste</h6>
															<span>{aluno.pessoa.pessoaTeste ? 'Sim' : 'Não'}</span>
														</div>
													</div>
												)}

												<div style={{ display: open[index] ? 'block' : 'none' }} id="example-collapse-text" className="collapsedText">
													<>
														<div className="dados-franquia">
															<div className="cpf">
																<h6>Empresa</h6>
																<span>{pessoaResponsavel.responsavel.descricaoEmpresa}</span>
															</div>
															<div className="cpf">
																<h6>Profissão</h6>
																<span>{pessoaResponsavel.responsavel.descricaoProfissao}</span>
															</div>
														</div>
														<div className="dados-franquia">
															<div className="cpf">
																<h6>CEP</h6>
																<span>{pessoaResponsavel.responsavel.endereco?.cep}</span>
															</div>
														</div>
														<div className="dados-franquia">
															<div className="cpf">
																<h6>Endereço</h6>
																<span>{pessoaResponsavel.responsavel.endereco?.logradouro}</span>
															</div>
														</div>
														<div className="dados-franquia">
															<div className="cpf">
																<h6>Numero</h6>
																<span>{pessoaResponsavel.responsavel.endereco?.numero}</span>
															</div>
															<div className="cpf">
																<h6>Complemento</h6>
																<span>{pessoaResponsavel.responsavel.endereco?.complemento}</span>
															</div>
														</div>
														<div className="dados-franquia">
															<div className="cpf" style={{ minWidth: '50%' }}>
																<h6>Telefone (1)</h6>
																<span>{pessoaResponsavel.responsavel.contatos?.find((c) => c.tipoContatoId === ContatoEnum.CEL)?.descricao}</span>
															</div>
														</div>
														<div className="dados-franquia">
															<div className="cpf" style={{ minWidth: '50%' }}>
																<h6>Telefone (2)</h6>
																<span>{pessoaResponsavel.responsavel.contatos?.find((c) => c.tipoContatoId === ContatoEnum.TEL)?.descricao}</span>
															</div>
														</div>
														<div className="dados-franquia">
															<div className="cpf">
																<h6>Email</h6>
																<span>{pessoaResponsavel.responsavel.contatos?.find((c) => c.tipoContatoId === ContatoEnum.EPS)?.descricao}</span>
															</div>
														</div>
													</>
												</div>
												<a onClick={() => handleMostrarMais(index)}>
													<p className="dethales-aluno">Mostrar {open[index] ? 'menos' : 'mais'} detalhes</p>
												</a>
											</div>
										))}
							</div>
							<div className="line" style={{ marginBottom: 20 }}></div>
							<div className="dados">
								<div className={'title-and-edit-button'}>
									<h4>Documentos</h4>

									<Button className="button-line" onClick={() => handleEditarClick('step-4')}>
										Editar
									</Button>
								</div>

								<div className="documentos-aluno">
									<div className="estado">
										<div className={isDocumentoEnviado(TipoDocumentoEnum.DIA) ? 'check-doc' : 'check-doc-red'}>
											{isDocumentoEnviado(TipoDocumentoEnum.DIA) ? (
												<Unicons.UilCheck size="16" color="#FFFFFF" />
											) : (
												<Unicons.UilTimes size="16" color="#FFFFFF" />
											)}
										</div>
										<p>Documento de identificação do aluno</p>
									</div>
								</div>
								<div className="documentos-aluno">
									<div className="estado">
										<div className={isDocumentoEnviado(TipoDocumentoEnum.DIRP) ? 'check-doc' : 'check-doc-red'}>
											{isDocumentoEnviado(TipoDocumentoEnum.DIRP) ? (
												<Unicons.UilCheck size="16" color="#FFFFFF" />
											) : (
												<Unicons.UilTimes size="16" color="#FFFFFF" />
											)}
										</div>
										<p>Documento de identificação do responsável principal</p>
									</div>
								</div>
								<div className="documentos-aluno">
									<div className="estado">
										<div className={isDocumentoEnviado(TipoDocumentoEnum.CPR) ? 'check-doc' : 'check-doc-red'}>
											{isDocumentoEnviado(TipoDocumentoEnum.CPR) ? (
												<Unicons.UilCheck size="16" color="#FFFFFF" />
											) : (
												<Unicons.UilTimes size="16" color="#FFFFFF" />
											)}
										</div>
										<p>Comprovante de residência</p>
									</div>
								</div>
							</div>
							<div className="line" style={{ marginBottom: 20 }}></div>
							<div className="tabela">
								<h4>Extratos Financeiros</h4>
								<div className="tabela">
									<div className="titles-users">
										<div className="item" style={{ width: '20%' }}>
											<span>FINANCEIRO</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '9%' }}>
											<span>PARCELA</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '10%' }}>
											<span>TIPO</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '13%' }}>
											<span>VENCIMENTO</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '15%' }}>
											<span>VALOR</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '16%' }}>
											<span>DATA PAGAMENTO</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '20%' }}>
											<span>STATUS</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
										<div className="item" style={{ width: '19%' }}>
											<span>DIAS VENCIDOS</span>
											<div className="arrows">
												<Unicons.UilDirection size="20" color="#92A9CB" />
											</div>
										</div>
									</div>
									{(!cobrancas?.content || cobrancas?.content?.length === 0) && (
										<>
											<p>Não foi encontrada nenhuma cobrança</p>
										</>
									)}
									{cobrancas?.content && 
										cobrancas.content.map((cobranca) => (
											<div className="registro-user" key={cobranca.id}>
												<div className="funcao-user" id="alunos" style={{ width: '18%' }}>
													<p></p>
													<Tags
														tipo={`# ${cobranca.financeiro.id} - 
														
														${descricaoProduto(cobranca)}`}
													/>
												</div>
												<div className="funcao-user" style={{ width: '5%' }}>
													<p>{cobranca.parcela}</p>
												</div>
												<div className="funcao-user" style={{ width: '13%' }}>
													<p>{cobranca.tipoPagamento?.descricao}</p>
												</div>
												<div className="funcao-user" style={{ width: '10%' }}>
													<p>{cobranca.dataVencimento && formattedDate(cobranca.dataVencimento)}</p>
												</div>
												<div className="funcao-user" style={{ width: '15%' }}>
													<p>{cobranca.valorCobranca && formatMoney(cobranca.valorCobranca)}</p>
												</div>
												<div className="funcao-user" style={{ width: '15%' }}>
													<p>{formattedDate(cobranca.dataPagamento)}</p>
												</div>

												<DivStatusCobranca
													codigo={cobranca?.statusCobranca?.codigo}
													descricao={cobranca?.statusCobranca?.descricao}
													tooltip={cobranca?.mensagemFalha}
													renegociacaoOriginada={cobranca?.renegociacaoOriginada}
													observacao={cobranca?.observacao}
													width="20%"
												/>

												<div className="funcao-user" style={{ width: '16%' }}>
													{cobranca?.dataPagamento < cobranca?.dataVencimento ||
													cobranca?.statusCobranca?.codigo == 'PGO' ||
													cobranca?.statusCobranca?.codigo == 'CAN' ||
													cobranca?.statusCobranca?.codigo == 'IST' ||
													cobranca?.statusCobranca?.codigo == 'RPC' ? (
														<p>{'0'}</p>
													) : (
														<p>{calculateDaysBetweenNoWeekend(cobranca?.dataVencimento)}</p>
													)}
												</div>
												{cobranca?.statusCobranca?.codigo === 'AGR' ||
												cobranca.statusCobranca?.codigo === 'CAN' ||
												cobranca?.statusCobranca?.codigo === 'FLH' ||
												cobranca?.statusCobranca?.codigo === 'IST' ||
												cobranca?.statusCobranca?.codigo === 'GRD' ||
												(cobranca?.statusCobranca?.codigo === 'PGO' &&
													(cobranca?.identificadorEnotas !== undefined || cobranca?.identificadorEnotasProduto !== undefined)) ||
												(cobranca?.statusCobranca?.codigo === 'PGO' && cobranca?.financeiro.cobrancaUnica === true) ||
												(cobranca?.statusCobranca?.codigo === 'FLH' &&
													(cobranca.tipoPagamento?.codigo === 'CRR' || cobranca.tipoPagamento?.codigo === 'CRC')) ? (
													<div className="funcao-user" style={{ width: '3%' }}>
														<Dropdown overlay={itensDropdown(cobranca)} placement="bottomRight" arrow>
															<Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
														</Dropdown>
													</div>
												) : (
													<div className="funcao-user" style={{ width: '3%' }}>
													{cobranca?.codigoArquivoBaixa && canBaixaManual && (
														<Dropdown overlay={dropDownForBaixa(cobranca)} placement="bottomRight" arrow>
															<Unicons.UilEllipsisH className="button-dropdown" size="18" color="#6A7C96" />
														</Dropdown>
															)}
													</div>
												)}
											</div>
										))}
								</div>

								<div className="paginacao">
									<Pagination
										onChange={(event, page) => onPageChange(event, page - 1)}
										page={cobrancas ? cobrancas.pageable.pageNumber + 1 : 0}
										count={cobrancas ? cobrancas.totalPages : 0}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div>{openRematricula && <MatriculasEmAndamento />}</div>

			<div>
				<ModalFinanceiroCancelamentoStep2 open={mostrarModalCancelamento} onClose={finalizaCancelamento} cobranca={cobrancaSelected} />
			</div>
			<div>
				<ModalEstorno open={mostrarModalEstorno} onClose={closeModalEstorno} cobranca={cobrancaSelected} />
			</div>
			<div>
				<ModalEditarVencimento open={mostrarModalEditarVencimento} onClose={closeModal} cobranca={cobrancaSelected} />
			</div>
			<div>
				<ModalFinanceiro open={isOpen} onClose={finalizaNegociacao} cobranca={cobrancaSelected} tela={'alunos'} />
			</div>
			<div>
				<ModalEditarPagamento open={mostrarModalEditarPagamento} cobranca={cobrancaSelected} onclose={closeModalAlterarPagamento} />
				<div>
					<ModalBaixaNew cobranca={cobrancaSelected} onClose={closeBaixaManual} open={mostrarModalBaixaManual} />
				</div>
			</div>
		</div>
	);
};
